<template>
  <div class="page-container order">
    <div class="page-header">
      <ace-header show-back>
        {{ $t('system.charge.buyNow') }}
      </ace-header>
    </div>
    <div class="page-body">
      <div class="border-bottom-6">
        <merchandise-item
          v-if="merchandiseData.commodity_uid"
          :info="merchandiseData"
          :showFooter="false"
        />
      </div>
      <div class="p-10px border-bottom-6">
        <!-- <div class="pb-10px px-10px">{{ $t('system.charge.chooseRole') }}</div> -->
        <van-cell
          :title="$t('system.charge.chooseRole')"
          title-class="text-primary"
          is-link
          :to="`/role-list?gid=${selectedGame.app_uid}`"
          class="p-10px bg-secondary rounded-6px"
        />
        <role-item v-if="roleData.id" :info="roleData" :limit="limitData" :show-action="false" />
      </div>
      <div class="p-20px">
        <div class="pb-10px">{{ $t('system.charge.paymentWay') }}</div>
        <van-radio-group v-model="selectedPaymentId" direction="horizontal">
          <van-radio
            v-for="item in paymentListData"
            :key="item.id"
            :name="item.id"
            checked-color="#ffd461"
          >
            <div class="p-4px bg-light-100 rounded-6px">
              <img :src="item.ico_url" class="w-72px h-36px" />
            </div>
          </van-radio>
        </van-radio-group>
      </div>
      <div class="p-20px">
        <van-button
          block
          type="primary"
          :disabled="disabled"
          :loading="loading"
          :text="$t('system.charge.payNow')"
          @click="onPay"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { loadAirwallex, redirectToCheckout } from 'airwallex-payment-elements';
  import { getRoleList, getRoleLimit, getPayment, getPayV2 } from '@/api/system';
  import { storage } from '@/utils/storage';
  import { isIOS } from '@/utils/is';
  import MerchandiseItem from '@/components/Merchandise.vue';
  import RoleItem from '@/components/RoleItem.vue';

  export default {
    name: 'Order',
    components: { MerchandiseItem, RoleItem },
    data() {
      return {
        merchandiseData: {
          app_uid: 0,
          commodity_description: '',
          commodity_name: '',
          commodity_stencli_uid: 0,
          commodity_uid: 0,
          currency_code_name: '',
          currency_real: '',
          currency_unit_id: 0,
          is_limit: false,
          lable_ids: '',
          limit_count: 0,
          pic_url: '',
          price: '',
        },
        paymentListData: [],
        roleData: {
          app_name: '',
          id: '',
          level: '',
          nick_name: '',
          player_id: '',
          server_id: '',
        },
        limitData: {
          roleBuyLimit: 0,
          isRoleBuyLimited: false,
        },
        selectedPaymentId: 1,
        disabled: true,
        loading: false,
      };
    },
    computed: {
      ...mapState({
        paymentList: (state) => state.system.paymentList,
      }),
      ...mapGetters({
        defaultGame: 'defaultGame',
      }),
      selectedGame() {
        return storage.get('SELECTED_PRODUCT_GAME') || this.defaultGame;
      },
    },
    methods: {
      async getPaymentData() {
        try {
          const res = await getPayment({
            app_uid: this.merchandiseData.app_uid,
            commodity_uid: this.merchandiseData.commodity_uid,
            currency_unit_id: this.merchandiseData.currency_unit_id,
          });
          if (res) {
            this.paymentListData = res;
          } else {
            this.paymentListData = this.paymentList || [];
          }
        } catch (error) {
          return error;
        }
      },
      async getRoleData() {
        try {
          const res = await getRoleList(this.selectedGame.app_uid);
          if (res.length) {
            this.roleData = res[0];
            await this.getRoleBuyLimit(res[0].id);
          }
        } catch (error) {
          return error;
        }
      },
      async getRoleBuyLimit(roleId) {
        try {
          const res = await getRoleLimit({
            app_uid: this.selectedGame.app_uid,
            commodity_uid: this.merchandiseData.commodity_uid,
            app_user_id: roleId,
            currency_unit_id: this.merchandiseData.currency_unit_id,
          });
          if (res) {
            this.limitData = {
              roleBuyLimit: res.limit_count - res.have_limit_count,
              isRoleBuyLimited: res.limit_count > 0,
            };
          }
        } catch (error) {
          return error;
        }
      },
      async onPay() {
        this.loading = true;
        const data = {
          app_uid: this.defaultGame.app_uid,
          commodity_uid: this.merchandiseData.commodity_uid,
          app_user_id: this.roleData.id,
          currency_unit_id: this.merchandiseData.currency_unit_id,
          pay_way_id: this.selectedPaymentId,
          os_type: isIOS() ? 'ios' : 'android',
        };
        const selectedPayment = this.paymentListData.find(
          (item) => item.id === this.selectedPaymentId
        );
        try {
          const res = await getPayV2(data);
          if (res.status !== 'SUCCESS') return;
          this.$toast({
            message: this.$t('system.charge.payJumpTips'),
            duration: 0,
          });
          try {
            if (res.url && res.type) {
              window.location.href = res.url;
            } else {
              const payEnv = process.env.VUE_APP_PAY_ENV;
              const fallBackUrl = process.env.VUE_APP_PAY_RESULT_URL;
              const localeShort = this.$i18n.locale.split('-')[0] || 'en';
              // Can choose other production environments, 'staging | 'demo' | 'prod'
              await loadAirwallex({ env: payEnv });
              redirectToCheckout({
                env: payEnv,
                intent_id: res.id,
                client_secret: res.client_secret,
                currency: res.currency,
                autoCapture: true,
                components: [selectedPayment.type],
                locale: localeShort,
                successUrl: `${fallBackUrl}?amount=${res.amount}&symbol=${res.currency_rel}`,
                failUrl: `${fallBackUrl}?amount=${res.amount}&symbol=${res.currency_rel}`,
              });
            }
          } finally {
            this.loading = false;
            this.$toast.clear();
          }
        } finally {
          this.loading = false;
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      if (from.name === 'role-list') {
        next((vm) => {
          const role = storage.get('SELECTED_ROLE');
          if (role) {
            vm.roleData = Object.assign(vm.roleData, role);
          }
        });
      } else {
        storage.remove('SELECTED_ROLE');
        next();
      }
    },
    beforeMount() {
      this.$nextTick(async () => {
        const merchandise = storage.get('MERCHANDISE_INFO');
        if (merchandise) {
          this.merchandiseData = Object.assign(this.merchandiseData, merchandise);
          await this.getPaymentData();
          if (!this.roleData.id) {
            await this.getRoleData();
          } else {
            await this.getRoleBuyLimit(this.roleData.id);
          }
        }
      });
    },
    beforeDestroy() {
      this.$toast.clear();
    },
    watch: {
      roleData: {
        handler(data) {
          if (data && data.id) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        },
        immediate: true,
        deep: true,
      },
    },
  };
</script>
