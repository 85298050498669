<template>
  <div class="py-20px px-10px bg-secondary" @click="onClick">
    <div class="mb-30px flex justify-between">
      <div class="w-240px leading-tight van-ellipsis">
        <span class="text-secondary">{{ $t('system.charge.gameName') }}</span>
        <span class="pl-20px">{{ info.app_name }}</span>
      </div>
      <div v-if="showAction" class="w-50px flex justify-between">
        <van-icon name="delete-o" size="16" @click.stop="onDelete" />
        <van-icon name="replay" size="16" @click.stop="onUpdate" />
      </div>
    </div>
    <ul class="w-full flex leading-none">
      <li class="w-100px">
        <div class="mb-10px text-secondary">{{ $t('system.charge.server') }}</div>
        <div class="van-ellipsis">{{ info.server_name || info.server_id }}</div>
      </li>
      <li class="w-100px">
        <div class="mb-10px text-secondary">{{ $t('system.charge.roleID') }}</div>
        <div class="van-ellipsis">{{ info.player_id }}</div>
      </li>
      <li class="w-135px">
        <div class="mb-10px text-secondary">{{ $t('system.charge.roleInfo') }}</div>
        <div class="van-ellipsis">{{ info.nick_name }}-{{ info.level }}</div>
      </li>
    </ul>
    <div v-if="limit.isRoleBuyLimited" class="mt-10px leading-none text-secondary">
      {{ $t('system.charge.roleLimit') }}
      <small class="text-primary">{{ limit.roleBuyLimit }}</small>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RoleItem',
    props: {
      info: {
        type: Object,
        default() {
          return {};
        },
      },
      showAction: {
        type: Boolean,
        default: true,
      },
      limit: {
        type: Object,
        default() {
          return {
            roleBuyLimit: 0,
            isRoleBuyLimited: false,
          };
        },
      },
    },
    methods: {
      onClick() {
        this.$emit('on-select', this.info);
      },
      onDelete() {
        this.$emit('on-delete', this.info.id);
      },
      onUpdate() {
        this.$emit('on-update', this.info.id);
      },
    },
  };
</script>
