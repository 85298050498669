<template>
  <div class="mx-auto merchandise-item" :class="showFooter ? 'normal' : 'no-footer'">
    <div class="mb-10px w-full h-204px overflow-hidden">
      <van-image class="w-full h-full" lazy-load :src="info.pic_url" :alt="info.commodity_name" />
    </div>
    <div :class="showFooter ? 'pb-20px px-10px' : 'px-20px'">
      <div class="mb-10px h-24px flex items-center">
        <template v-if="labels.length">
          <img
            v-for="item in labels"
            :key="item"
            width="24"
            height="24"
            class="mr-2"
            :src="hotImgList[`hot_${item}`]"
          />
        </template>
        <div class="text-primary text-large font-bold van-ellipsis">
          {{ info.commodity_name }}
        </div>
      </div>
      <div class="mb-30px text-medium leading-5">
        {{ info.commodity_description }}
      </div>
      <div class="mb-20px h-24px flex items-end font-bold">
        <div class="text-primary">
          <span class="pr-4px">{{ info.currency_code_name }}</span>
          <span class="text-large">{{ info.price }}</span>
        </div>
      </div>
      <template v-if="showFooter">
        <div class="mx-auto mb-6px custom-button" @click="handleBuy">
          {{ $t('system.charge.buyNow') }}
        </div>
        <div v-if="info.is_limit" class="text-center text-secondary">
          {{ $t('system.charge.buyLimit', { limit: info.limit_count }) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { userValidate } from '@/api/user';

  import hot_1 from '@/assets/hot_1.png';
  import hot_2 from '@/assets/hot_2.png';

  export default {
    name: 'MerchandiseItem',
    props: {
      info: {
        type: Object,
      },
      showFooter: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        hotImgList: { hot_1, hot_2 },
      };
    },
    computed: {
      labels() {
        if (this.info.lable_ids) {
          return this.info.lable_ids.split(',');
        }
        return [];
      },
    },
    methods: {
      handleBuy: async function () {
        try {
          const code = await userValidate();
          if (code === 1) {
            this.$emit('on-click', this.info, true);
          }
          if (code === 401) {
            this.$emit('on-click', this.info, false);
          }
        } catch (e) {
          return e;
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .merchandise-item {
    &.normal {
      margin-bottom: 20px;
      width: 354px;
      min-height: 438px;
      border: 1px solid #31363e;
      border-radius: 2px;
      background-color: #1e2228;
    }
    &.no-footer {
      margin-bottom: 0;
      width: 100%;
      min-height: 332px;
      border: none;
    }
  }
</style>
